.wrapper {
    background-color: var(--bg-gray);
    color: var(--text-color-black);
    display: flex;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--bg-color-hover);
    }
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.info {
    flex: 1;
    margin-left: 12px;
}

.name {
    font-size: 1.6rem;
    font-weight: 600;
}
.check {
    margin-left: 6px;
    color: var(--tick-color);
    font-size: 1.4rem;
}

.username {
    font-size: 1.4rem;
    color: var(--text-color-gray);
}
