.wrapper {
    width: 100%;
}
.header {
    width: 100%;
}
.header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 4px;
}
.info-body {
    display: flex;
}
.header-avatar {
    width: 41px;
    height: 41px;
    min-width: 41px;
    min-height: 41px;
    margin: 0px 12px;
    border-radius: 50%;
}
.body-name {
    display: flex;
    flex-direction: column;
}
.info {
    display: flex;
}
.nickname {
    color: var(--text-color-black);
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.4rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.name {
    color: var(--text-color-black);
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 0px 0px 4px;
    white-space: nowrap;
}
.btn-follow {
    color: white;
}
.content {
    display: flex;
    align-items: center;
}
.content-text {
    font-weight: 400;
    margin-right: 12px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--text-color-gray);
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.viewer {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--text-color-black);
    margin-inline-end: 10px;
}
.people-counter {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
}
.actions {
    display: flex;
    width: 170px;
}
.actions-icon {
    margin-right: 6px;
    border: 1px solid var(--border-color);
    background-color: var(--bg-color);
    color: var(--text-color-black);
    width: 34px;
    height: 34px;
    display: flex;
    padding: 3px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.header-video {
    width: 100%;
    position: relative;
    background-image: linear-gradient(to bottom right, rgb(41, 27, 27), rgb(25, 8, 13));
    padding: 20px 0 14px;
    height: 70vh;
}
.video {
    width: 100%;
    height: 100%;
}
.live-inner {
    width: 100%;
}
.live-label {
    position: absolute;
    color: var(--text-color-white);
    font-size: 1.5rem;
    top: 24px;
    font-weight: 600;
    border-radius: 6px;
    padding: 2px 4px;
    right: 6px;
    background-color: var(--primary);
}

.video-rec-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.title-recommend {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: bold;
    color: var(--text-color-black);
    margin: 30px 0 16px;
}
@media (max-width: 560px) {
    .actions-icon,
    .name {
        display: none;
    }
    .header-info {
        display: flex;
        justify-content: space-between;
    }
    .actions {
        width: 100px;
    }
    .header-video {
        height: 34vh;
    }
}
