.wrapper {
    // margin-bottom: 26px;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    perspective: 1px;
    transform-style: preserve-3d;
    perspective-origin: right top;
    top: calc(var(--default-layout-header-height) + 1px);
    bottom: 0;
    width: var(--width-sidebar);

    background: var(--bg-color-sidebar);
    position: fixed;
    padding-right: 8px;

    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 10px rgb(252, 248, 248);
    }
    &:hover {
        &::-webkit-scrollbar {
            display: block;
            width: 6px;
            height: 800px;
        }
    }
}

.wrapper.medium {
    width: 240px;
}
// sidebar login
.sidebar-login {
    border-top: 1px solid var(--border-color);
    padding: 20px 0;
    margin: 10px 0;
}
.sidebar-login-text {
    color: var(--text-color-gray);
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 20px;
}
.sidebar-login-btn {
    width: 100%;
    font-weight: 700;
}
.footer {
    padding-top: 14px;
    width: 100%;
    font-size: 1.2rem;
    color: var(--text-color-gray);
    line-height: 1.7rem;
    display: inline-block;
    margin-bottom: 12px;
    font-weight: 400;
    border-top: 1px solid var(--border-color);
}
.footer-list {
    display: flex;
    margin-bottom: 8px;

    p {
        margin-right: 6px;
        margin-top: 5px;

        &:hover {
            text-decoration: underline;
        }
    }
}
.end {
    display: block;
    margin: 26px 0 16px;
    color: var(--text-color-gray);
    font-weight: 600;
    font-size: 1.2rem;
}

@media (max-width: 380px) {
    .wrapper {
        display: none;
    }
}
@media (max-width: 740px) {
    .wrapper {
        width: 72px;
        border-right: 1px solid var(--border-color);
        margin: 0 -18px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .sidebar-login,
    .footer {
        display: none;
    }
    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .wrapper.medium {
        width: 76px;
    }
}
@media (max-width: 400px) {
    .wrapper{
        margin-right: 0;
        padding: 0;
    }
}
