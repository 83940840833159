@media (max-width: 740px) {
    .search {
        display: none;
    }
}
.dark-mode {
    background-color: var(--bg-color);
    color: var(--text-color-black);
}

.wrapper {
    --search-border-radius: 92px;
    --search-height: 46px;
    --search-top-spacer: 9px;
    --search-button-width: 52px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--default-layout-header-height, 60px);
    box-shadow: var(--box-shadow);
    background-color: var(--bg-color-white);
    display: flex;
    justify-content: center;
    z-index: 2;
}

.inner {
    height: 100%;
    width: var(--default-layout-width);
    padding: 0 var(--default-layout-horizontal-spacer);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color);
}
.logo-link {
    display: flex;
}
.image {
    width: 150px;
}
.actions {
    display: flex;
    align-items: center;
}
// mode dark
.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 44px;
    height: 24px;
    padding: 2px;
    background-color: rgba(22, 22, 22, 0.012);
    // border: 1px solid rgba(22, 22, 22, 0.5);
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 4px;

    border-radius: 22px;
    position: relative;
    padding: 0 3px;
}
.turn-on {
    position: relative;
    background-color: #2374e1;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    .switch-inner {
        position: absolute;
        left: calc(100% - 22px);
    }
}
.switch-inner {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--bg-color);
    // background-color: #2374e1;
    border-radius: 50%;
    left: 2px;
    box-shadow: rgb(0 0 0 / 50%) 0px 1px 2px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
// mode dark
.btn-upload {
    color: var(--text-color-black);
}
.more-btn {
    padding: 8px;
    margin-left: 16px;
    font-size: 2rem;
    background-color: var(--transparent-color);
    cursor: pointer;
}

.action-btn {
    position: relative;
    color: var(--text-color-black);
    background-color: transparent;
    font-size: 2.2rem;
    padding: 4px 10px;
    height: 100%;
    cursor: pointer;
}

.badge {
    position: absolute;
    background-color: #fe2c55;
    border-radius: 10px;
    font-size: 1.4rem;
    right: -2px;
    top: -3px;
    color: white;
    line-height: 2rem;
    font-weight: 600;
    padding: 0 6px;
}

.user-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
}
.message-icon {
    width: 25px;
}
.btn-login {
    color: white;
}
