.wrapper {
    width: 100%;
    height: 100vh;
}
// update
.body {
    width: 100%;
    background-color: var(--bg-gray);
    margin: var(--default-layout-header-height) auto 0;
    padding: 16px 0;
}
.loading-icon {
    width: 100%;
    color: red;

    position: fixed;
    z-index: 999;
    top: 50px;
    display: flex;
    justify-content: center;
}
.icon-load {
    width: 20px;
    height: 20px;
}
.upload {
    max-width: 1100px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
    border-radius: 8px;
    background-color: var(--bg-color);
    padding: 24px 56px;

    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
// body upload
.upload-title {
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--text-color-black);
}
.upload-title-small {
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--text-color-gray);
    margin: 4px 0;
}
.upload-body {
    display: flex;
    margin: 24px 0 130px;
}
.left {
    position: relative;
    margin-top: 24px;
    width: 260px;
    height: 458px;
    border: 2px dashed var(--border-color);
    border-radius: 8px;
    padding: 0 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &:hover {
        cursor: pointer;
        border-color: var(--primary);
    }
}
// left background
.left-video-background {
    position: absolute;
    width: 260px;
    height: 480px;
}
.left-video {
    left: 12px;
    top: 8px;
    position: absolute;
    border-radius: 3px;
    width: 235px;
    height: 426px;
}

.input-upload {
    display: none;
}
.left-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.left-icon {
    width: 40px;
    height: 29px;
}
.left-text-main {
    margin-top: 24px;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--text-color-black);
}
.left-text-sub {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-color-gray);
    margin: 4px 0 24px;
}
.left-text-video-info {
    color: var(--text-color-gray);

    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 4px;
}
.left-select-btn {
    margin-top: 40px;
    width: 100%;
    border-radius: none;
    color: white;
}
// right
.right {
    margin-left: 24px;
    border-radius: 1px;
    flex: 1;
}
.caption-wrap {
    margin-bottom: 24px;
}
.label {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--text-color-black);
}
.label-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: var(--bg-black);
    }
}
.box {
    width: 100%;
    min-height: 46px;
    border-radius: 6px;
    margin-top: 4px;
    padding: 0 16px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
}
.box-input {
    display: flex;
    justify-content: space-between;
    margin: 0;
    align-items: center;
}
.input {
    height: 46px;
    width: 100%;
    font-size: 1.6rem;
    padding: auto 8px;
}
.input-style {
    font-weight: 600;
    font-size: 1.8rem;

    span {
        margin-left: 6px;
    }
}
.box-cover {
    padding: 6px;
}
.cover-video {
    width: 85px;
    height: 150px;
    object-fit: cover;
    background-color: var(--bg-gray);
}
.box-public {
    height: 36px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    :hover {
        cursor: pointer;
    }
}
.active {
    display: block;
}
.route {
    transform: rotate(180deg);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.icon-down {
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.choose-module {
    display: block;
    position: absolute;
    width: 100%;
    background-color: var(--bg-color);
    top: 120%;
    left: 0;
    border-radius: 6px;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

    z-index: 1;
    box-shadow: 0 1px 3px rgba(22, 24, 35, 0.5);
}
.choose-item {
    display: block;
    width: 100%;

    :hover {
        background-color: var(--bg-color-hover);
    }
}
.choose-item:first-child {
    background-color: var(--bg-gray);
}
.choose-text {
    display: block;
    padding: 6px 16px;
}
.allow-check {
    display: flex;
    align-items: center;
}
.allow-check-item {
    display: flex;
    align-items: center;
    margin-right: 32px;

    p {
        margin-left: 8px;
        height: 1.8rem;
    }
}

input[type='checkbox'] {
    position: relative;
    cursor: pointer;
}
input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    background-color: var(--bg-color);
}

input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 8px;
    border: 2px solid var(--primary);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 5px;
}
.label-run {
    display: flex;
    align-items: center;
    p {
        margin-right: 16px;
    }
}
.switch {
    display: flex;
    align-items: center;
    width: 44px;
    height: 24px;
    padding: 2px;
    background-color: var(--bg-gray);
    border-radius: 22px;
    position: relative;
}
.turn-on {
    position: relative;
    background-color: var(--tick-color);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    .switch-inner {
        position: absolute;
        left: calc(100% - 22px);
    }
}
.switch-inner {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--bg-color);
    border-radius: 50%;
    left: 2px;
    box-shadow: rgb(0 0 0 / 15%) 0px 1px 2px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.text-run-off {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color-black);
    margin: 20px 0;
    line-height: 2.4rem;
    span {
        cursor: pointer;
        font-weight: 700;
    }
}
.text-run-on {
    margin: 6px 0 26px;
    padding: 8px 12px;
    border-radius: 6px;
    height: 38px;
    background-color: var(--bg-gray);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    p {
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--text-color-black);
    }
}
.text-run-on-icon {
    color: var(--primary);
    margin-right: 6px;
}
.btn {
    margin-right: 8px;
    border-color: var(--border-color);
    color: var(--text-color-black);
    outline: none;
    width: 164px;

    &:hover {
        border-color: var(--border-color);
        background-color: var(--bg-color-hover);
    }
}
.btn.btn-active {
    background-color: var(--primary);
    color: var(--text-color-white);
    opacity: 1;

    &:hover {
        background-color: var(--primary);
        cursor: pointer;
        opacity: 0.9;
    }
}
.btn.disabled {
    cursor: no-drop;
    opacity: 0.5;
}

// update
.footer {
    background-color: black;
    color: white;
    display: flex;

    flex-direction: column;
    padding-bottom: 30px;
}
.footer-start {
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}
.image {
    width: 20%;
    padding-left: 40px;
}
.footer-list {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding-left: 40px;
}
.footer-label {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.8rem;
    padding-bottom: 12px;
}
.footer-item {
    color: #ccc;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.8rem;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
.footer-end {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
}
.footer-lang {
    justify-content: space-between;
    align-items: center;

    display: flex;
    border: 1px solid #8a8b91;
    font-weight: 400;
    width: 172px;
    height: 36px;
    line-height: 3.6rem;
    padding: 0 16px;

    &:hover {
        cursor: pointer;
    }
}

.footer-copy-right {
    color: #8a8b91;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.4rem;
}
@media (max-width: 740px) {
    .upload-body {
        flex-direction: column;
        align-items: center;
    }
    .upload-title {
        text-align: center;
    }
    .upload-title-small {
        text-align: center;
    }
    .right{
        margin-top: 20px;
    }
    .btn-body {
        display: flex;
        justify-content: space-around;
    }
    .image {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 0 20px 0;
    }
    .footer-list {
        width: 50%;
    }
}
