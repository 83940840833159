.wrapper {
    width: 100%;
}
.wrapper-following {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.body-video{
    background-color: var(--bg-color);
}
.following-video-item {
    width: 32%;
    border-radius: 6px;
}
.following-video{
    border-radius: 6px;
}
@media (min-width: 740px) and (max-width: 970px) {
    .following-video-item {
        width: 49%;
    }
}
@media (max-width: 640px) {
    .following-video-item {
        width: 49%;
    }
}
@media (max-width: 500px) {
    .following-video-item {
        width: 100%;
    }
    .item {
        
        min-height: auto;
        
    }
    .video-body {
        min-height: auto;
    }
    .following-video {
        min-height: auto;
    }
}
.item {
    position: relative;
    width: 100%;
    // min-height: 433px;
    border-radius: 8px;
    float: left;
    overflow: hidden;
    background-color: var(--bg-gray);
}
.video-body {
    width: 100%;
    // height: 433px;
}
.following-video {
    width: 100%;
    min-height: 430px;

    object-fit: cover;
}

.following-info {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
}
.following-btn {
    width: 80px;
    color: white;
}
.info {
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    margin: 0px;
    text-align: center;
}
.nickname {
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    max-width: 180px;
    margin: 0px auto 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.icon {
    color: var(--tick-color);
    font-size: 1.4rem;
    margin: 0 0 4px 4px;
}
