.loading {
    width: 10%;
    height: 10%;
    animation: rotation 2s infinite linear;
    background-color: white;
    border-radius: 50%;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
