.share {
    display: flex;
    flex-direction: column;
    width: 220px;
    color: black;
    background-color: white;
    font-size: 1.6rem;
    border-radius: 12px;
    box-shadow: var(--box-shadow-share);
}
.share-item {
    display: flex;
    align-items: center;
    padding: 6px 16px;

    span {
        font-weight: 600;
    }

    &:nth-child(1) .share-icon {
        margin-top: 6px;
        color: var(--text-color-white);
        background-color: rgb(158, 35, 35);
    }
    &:nth-child(2) .share-icon {
        color: var(--text-color-white);
        background-color: var(--primary);
    }
    &:nth-child(3) .share-icon {
        color: white;
        background-color: rgb(50, 50, 237);
    }
    &:nth-child(4) .share-icon {
        color: var(--text-color-white);
        background-color: rgb(47, 255, 68);
    }
    &:nth-child(5) .share-icon {
        color: var(--text-color-white);
        background-color: var(--primary);
        margin-bottom: 6px;
    }

    &:hover {
        background-color: rgba(25, 25, 25, 0.09);
    }
}
.share-icon {
    font-size: 3rem;
    margin-right: 8px;
    border-radius: 50%;
    padding: 6px;
}
.icon-down {
    padding: 8px auto;
}
