.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}
@keyframes fadeIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(100%);
        opacity: 100%;
    }
}
.body {
    width: 700px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
    box-shadow: 0 1px 5px rgba(236, 94, 11, 0.5);
    margin: auto;
    animation: fadeIn linear 0.2s;
    display: flex;
    flex-direction: column;
}
.header {
    padding: 24px 24px 12px;
    font-weight: 600;
    font-size: 2.4rem;
    display: flex;
    justify-content: space-between;
    color: var(--text-color-black);
    border-bottom: 0.5px solid var(--border-color);
}
.icon-close {
    background-color: var(--bg-color-hover);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: var(--border-color);
    }
}
.content {
    padding: 8px 24px 0px;
}
.content-item {
    padding: 16px 0px;
    display: flex;
    border-bottom: 1px solid var(--border-color);
}
.content-label {
    font-weight: 600;
    line-height: 2.4rem;
    font-size: 1.6rem;
    width: 120px;
    margin-right: 24px;
}
.avatar-content {
    position: relative;
    height: 96px;
    margin-left: 128px;

    &:hover {
        cursor: pointer;
    }
}
.input-edit {
    display: none;
}
.avatar {
    border-radius: 50%;
    width: 96px;
    height: 96px;
    object-fit: cover;
}
.icon-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 50%;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-area {
    p {
        font-size: 1.2rem;
        color: var(--text-color-gray);
        line-height: 1.8rem;
        margin-top: 16px;
        max-width: 460px;
        overflow: hidden;
        width: 360px;
    }
}
.input-text {
    width: 360px;
    height: 38px;
    border-radius: 4px;
    background: var(--bg-gray);
    caret-color: var(--primary);
    font-size: 16px;
    color: var(--text-color-gray);
    line-height: 2.4rem;
    outline: none;
    border: none;
    padding: 7px 12px;
    box-sizing: border-box;
}
.input-text.bio {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.input-bio {
    width: 340px;
    border-radius: 4px;
    background-color: transparent;
    caret-color: var(--primary);
    font-size: 16px;
    color: var(--text-color-gray);
    line-height: 24px;
    outline: none;
    border: none;
    box-sizing: border-box;
    height: 100px;
    resize: none;

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}
.text-count {
    color: var(--text-color-gray);
    font-size: 12px;
    line-height: 18px;
    background-color: var(--bg-color);
}
.last-item {
    padding-bottom: 30px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: auto 0;
    padding: 16px 24px;
}
.btn {
    border-radius: 4px;
    border: 1px solid var(--border-color);
    color: var(--text-color-gray);
    background-color: var(--bg-color);
    min-height: 28px;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    min-width: 0px;
    padding: 7px 16px;
    width: 96px;
    height: 36px;
    margin-left: 16px;

    &:hover {
        cursor: pointer;
        background-color: var(--bg-color-hover-btn-following);
    }
}
.btn.active {
    background-color: var(--primary);
    color: white;
    &:hover {
        background-color: rgb(243, 17, 62);
    }
}
@media (max-width: 560px) {
    .content-item {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 0 12px 0;
    }
    .content-label {
        margin: 24px;
        padding: 0;
        text-align: center;
    }
    .avatar-content {
        margin: 0;
    }
    .edit-area {
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
    }
}
