.wrapper {
    padding: 20px 0 26px 8px;
    border-top: 1px solid var(--border-color);
    
}
.label {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--text-color-black);

    margin-bottom: 8px;
}
.item {
    display: flex;
    padding: 8px 0;

    &:hover {
        background: var(--bg-color-hover);
    }
}
.info {
    margin-left: 12px;
    flex: 1;
}
.avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
}
.avatar.large {
    width: 50px;
    height: 50px;
}
.nick-name {
    color: var(--text-color-black);
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
}
.nick-name.bold {
    font-size: 1.8rem;
}
.icon {
    color: var(--tick-color);
    font-size: 1.4rem;
    margin: 0 0 4px 4px;
}
.name {
    color: var(--text-color-black);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
}
.name.medium {
    font-weight: 400;
    color: var(--text-color-black);
    font-size: 1.4rem;
    padding: 4px 0;
}
.more-btn {
    display: block;
    color: var(--primary);
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 8px;
    padding: 0 8px;
    cursor: pointer;
}
// new account
.account {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
    border-radius: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 16px;
    min-width: 310px;
    z-index: 9999;
}
.account-header {
    margin-bottom: 6px;

    position: relative;
}
.info-follow {
    margin: 4px 0 0 0;
}
.vote-body {
    color: var(--text-color-black);
}
.vote-body .vote:nth-child(2) {
    margin-left: 20px;
}
.vote {
    margin-right: 4px;
}
.btn-small-account {
    color: white;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.btn-account-follow {
    border-color: var(--border-color);
    color: var(--text-color-gray);

    &:hover {
        background-color: var(--bg-color);
    }
}
@media (max-width: 740px) {
    .label,
    .info,
    .more-btn {
        display: none;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
    }
}
