.item-wrapper {
    max-width: 24%;
    // width: 300px;
    // padding: 12px;

    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.item-video-body {
    width: 100%;
    background-color: var(--bg-color);
    position: relative;
    padding: 0;margin: 0;
    // background-color: red;
    border-radius: 6px;
}
.item-video {
    width: 100%;
    // object-fit: cover;
    // min-height: 330px;
    // background-color: red;
    // height: 100%;
    border-radius: 6px;
}
.item-viewers {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 22px;
    font-weight: 600;
    color: white;

    left: 8px;
    line-height: 1.2rem;
}
.item-play-icon {
    color: white;
    font-size: 3rem;
}
.item-video-content {
    font-weight: 400;
    padding: 2px 4px 0px;
    height: 2.8rem;
    box-sizing: content-box;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
@media (max-width: 950px) {
    .item-wrapper {
        max-width: 32%;
    }
}
@media (max-width: 940px) {
    // .item-wrapper {
    //     max-width: 30%;
    // }
}
@media (max-width: 840px) {
    .item-wrapper {
        max-width: 48%;
    }
}
@media (max-width: 540px) {
    .item-wrapper {
        max-width: 100%;
    }
}
