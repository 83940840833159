.wrapper {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}
.info {
    display: flex;
    justify-content: space-between;

    &:hover .actions-icon:first-child {
        display: block;
    }
}
.active {
    p {
        color: red;
    }
}
.icons {
    margin-top: 8px;
}
.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
}

.body {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.nickname {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: var(--text-color-black);
    &:hover {
        text-decoration: underline;
    }
}
.comment {
    font-size: 1.6rem;
    line-height: 2.2rem;
    white-space: pre-line;
    word-break: break-word;
    margin-bottom: 6px;
}
.time {
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--text-color-gray);

    span {
        margin-right: 24px;
    }
}
.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 18px;
    padding-top: 24px;
    position: relative;
}
.dots:hover .actions-delete {
    display: block;
}
.actions-delete {
    display: none;
    position: absolute;
    top: 24px;
    right: 0px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    background-color: white;
    padding: 6px 20px;
    border-radius: 6px;
    font-size: 1.6rem;
    &:hover {
        cursor: pointer;
        color: red;
    }
}
// .actions-delete::after {
//     position: absolute;
//     top: 8px;
//     right: -12px;
//     content: '';

//     width: 60px;
//     height: 5px;
//     background-color: red;
// }
.actions-icon:first-child {
    display: none;
    position: absolute;
    top: 0;
    font-size: 2.4rem;
}
.actions-icon:last-child {
    color: var(--text-color-gray);
    font-size: 1.5rem;
    line-height: 1.7rem;
    width: 20px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;

    .icons {
        margin-bottom: 4px;
    }
}
.reply {
    cursor: pointer;
}
// more comment
.more-comments {
    padding-left: 52px;
    margin: 16px 0;
}
.more-comments-footer {
    color: var(--text-color-gray);
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    position: relative;

    div:hover {
        text-decoration: underline;
    }
}
.btn-hidden {
    position: absolute;
    right: 0;
}
.more-comments-icon {
    margin-left: 6px;
    font-size: 1.2rem;
}
.more {
    display: none;
}
.show {
    display: block;
}
