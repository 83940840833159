.wrapper {
    width: 100%;
    height: 100vh;
    background-color: var(--bg-color);
    display: flex;
    justify-content: center;
}
.body-container {
    display: flex;
    justify-content: space-between;
    margin-top: var(--default-layout-header-height);
    width: 100vw;
    max-width: 1150px;
    height: 90vh;
    padding: 16px 24px 10px 20px;
    background-color: var(--bg-color);
}
.conversation-list {
    border-radius: 8px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 356px;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: var(--bg-color);
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
}
.back-icon {
    position: absolute;
    top: 8px;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    border-radius: 100%;
    background-color: var(--bg-gray);
    cursor: pointer;
    transform: none;
    left: -56px;
    padding-left: 10px;
}
.conversation-header {
    width: 100%;
    height: 60px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 16px 0px 24px;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
}
.title {
    font-size: 2.4rem;
    line-height: 28px;
    color: var(--text-color-black);
    font-weight: 700;
}
.setting-icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
}
.item {
    position: relative;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        .more-icon {
            display: block;
        }
    }
}
.item-info-name {
    font-size: 1.4rem;
}
.active {
    background-color: var(--bg-gray);
}
.more-icon {
    display: none;
    font-size: 2.6rem;
    color: var(--text-color-black);
}
// more\
.more {
    position: relative;
}
.more-list {
    background-color: white;

    position: absolute;
    top: 30px;
    right: -20px;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: var(--bg-color);
    box-shadow: 0 0 2px rgba(22, 24, 35, 0.5);
}
.more-item {
    background-color: white;
    display: flex;
    align-items: center;
    width: 180px;
    padding: 6px;
    border-bottom: 1px solid rgba(22, 24, 35, 0.12);

    &:hover {
        color: #fe2c55;
    }

    p {
        margin-left: 8px;
        font-size: 1.6rem;
        font-weight: 400;
    }
}
.more-item:last-child {
    border: none;
}
.no-message {
    height: 72px;
    padding: 0 16px;
    font-size: 1.4rem;
    background-color: var(--bg-gray);
    color: var(--text-color-black);
    line-height: 7.2rem;
}
.item-info {
    display: flex;
}
.item-info-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
}
.item-info-text {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.item-info-nickname {
    font-size: 1.6rem;
    color: var(--text-color-black);
    font-weight: 600;
}
.item-info-time {
    padding: 4px 0 0 4px;
    color: var(--text-color-gray);
    font-weight: 400;
    font-size: 1.2rem;

    span {
        margin-left: 4px;
    }
}
.item-border {
    border-bottom: 0.5px solid var(--border-color);
}
// boxchat
.chat-box {
    z-index: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 728px;
    height: 100%;
    background: var(--bg-color);
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 8px;
    border-radius: 8px;
    margin-left: 16px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: var(--text-color-black);
}
.chat-box-main {
    width: 100%;
    height: 70vh;
    padding: 6px 12px 0;
    overflow-y: scroll;
}
.chat-box-bottom {
    display: flex;
    justify-content: space-between;
    height: 66px;
    width: 100%;
    padding: 13px 16px;
    border-top: 0.5px solid var(--border-color);
}
.chat-box-bottom-body {
    // flex: 1;
    width: 100%;
    position: relative;
    background-color: var(--bg-gray);
    border-radius: 8px;
    padding: 8px 42px 8px 16px;
    font-size: 1.6rem;
}
.chat-box-input {
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    color: var(--text-color-black);
    background-color: transparent;
}
.chat-box-emotion {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.2rem;
}
// hover
.emotion-body:hover .emotion-hover {
    display: block;
}
.emotion-hover {
    display: none;
    position: absolute;
    background-color: var(--text-color-gray);
    top: -36px;
    right: -46px;
    padding: 6px 14px;
    color: var(--text-color-white);
    font-size: 1.4rem;
    border-radius: 20px;
}
.send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;
    margin-bottom: 3px;
    cursor: pointer;
    margin-left: 16px;
}
.send-icon {
    color: var(--primary);
    font-size: 2.6rem;
    transform: rotate(45deg);
    &:hover {
        transform: scale(1.2);
    }
}
@media (max-width: 850px) {
    .conversation-list {
        width: 200px;
    }
}
@media (max-width: 550px) {
    .body-container {
        flex-direction: column;
    }
    .conversation-list {
        width: 100%;
        height: auto;
    }
    .chat-box-header {
        display: none;
    }
    .chat-box {
        margin-left: 0;
        width: 100%;
    }
    .chat-box-main {
        height: 100%;
    }
    .chat-box-bottom {
        width: 100%;
    }
    .back-icon {
        left: 5px;
    }
    .title {
        margin-left: 32px;
    }
}
